<template>
  <div
    v-expose="{
      id: '2-21-13',
      data: {
        goods_num: underAnalysisData.goods_num,
        theme_id: underAnalysisData.theme_id,
        look_type: underAnalysisData.look_type,
      },
    }"
    class="newGtl-underImage"
    @click="clickFn"
  >
    <div class="title">
      <div class="title-text">
        {{ language.SHEIN_KEY_PC_17109 }} A
      </div>
      <div class="title-total">
        {{ relatedGoods.length - 1 }} {{ language.SHEIN_KEY_PC_21781 }}
      </div>
    </div>
    <div
      ref="listRef"
      class="list"
    >
      <div
        v-for="(item, index) in realRelatedGoods"
        :key="index"
        class="list-item"
        :class="{ 'list-imgFirst': index === 0 }"
      >
        <img
          :src="transformImg({ img: item.goods_img })"
          class="list-img"
        />
        <div
          v-if="index === 0"
          class="list-itemLine"
        ></div>
        <div
          v-if="moreNum && index === realRelatedGoods.length - 1"
          class="list-itemMask"
        >
          +{{ moreNum }}
        </div>
      </div>
    </div>
    <div
      ref="rightRef"
      class="right"
    >
      <div class="right-price">
        <div
          class="right-priceCount"
          :class="{
            'right-priceNormal': !isDiscount
          }"
        >
          {{ amountWithSymbol }}
        </div>
        <div
          v-if="isDiscount"
          class="right-pricePrecent"
        >
          {{ GB_cssRight? `-%${discount}`: `-${discount}%` }}
        </div>
      </div>
      <div class="right-btn">
        {{ language.SHEIN_KEY_PC_14591 }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { usePreloadGetTheLookPopup } from 'public/src/pages/goods_detail_v2/components/RecommendRelatives/GetTheLookNew/preloadCompositionUtils.js'
import { transformImg } from '@shein/common-function'
const { GB_cssRight } = gbCommonInfo

usePreloadGetTheLookPopup()

const props = defineProps({
  language: {
    type: Object,
    default: () => ({})
  },
  relatedGoods: {
    type: Array,
    default: () => ([])
  },
  isDiscount: {
    type: Boolean,
    default: false,
  },
  amountWithSymbol: {
    type: String,
    default: '',
  },
  discount: {
    type: String,
    default: '',
  },
  underAnalysisData: {
    type: Object,
    default: () => ({})
  },
  openGetTheLookNewPopup: {
    type: Function,
    default: () => () => {}
  },
})

const listRef = ref(null)
const maxItems = ref(0)
const rightRef = ref(null)

const realRelatedGoods = computed(() => {
  if (maxItems.value) {
    return props.relatedGoods.slice(0, maxItems.value)
  }
  return props.relatedGoods
})
const moreNum = computed(() => {
  if (maxItems.value && props.relatedGoods.length > maxItems.value) {
    return props.relatedGoods.length - maxItems.value
  }
  return 0
})

const clickFn = (e) => {
  const ifFromBtn = rightRef.value?.contains?.(e?.target)
  daEventCenter.triggerNotice({
    daId: '2-21-14',
    extraData: {
      click_type: ifFromBtn ? 'button' : 'image',
      theme_id: props.underAnalysisData?.theme_id,
      look_type: props.underAnalysisData?.look_type,
    }
  })
  props.openGetTheLookNewPopup?.()
}

onMounted(() => {
  const listWidth = listRef.value.offsetWidth || 0
  if (listWidth) {
    const MainImageWidth = 75
    const imageWidth = 66
    maxItems.value = Math.floor((listWidth - MainImageWidth) / imageWidth) + 1
  }
})
</script>

<style lang="less" scoped>
.newGtl-underImage {
  background: #F7F8FA;
  display: flex;
  padding: 7.5px 16px;
  align-items: center;
  cursor: pointer;
  margin-top: 23px;
  line-height: normal;

  .title {
    font-size: 16px;
    text-align: center;
  }

  .title-text {
    color: var(---sui_color_gray_dark1, #222);
    font-weight: 700;
  }

  .title-total {
    color: var(---sui_color_gray_dark2, #666);
    font-weight: 400;
  }

  .list {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: nowrap;
    overflow: hidden;
  }

  .list-item {
    display: flex;
    align-items: center;
    margin-left: 8px;
    position: relative;
  }

  .list-img {
    width: 58px;
    height: 58px;
    border: 1px solid #FFF;
    border-radius: 2px;
    object-fit: cover;
    overflow: hidden;
  }

  .list-imgFirst {
    border: none;
  }

  .list-itemLine {
    margin-left: 8px;
    width: 0.5px;
    height: 64px;
    background: #CCC;
  }

  .list-itemMask {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    overflow: hidden;

    font-size: 16px;
    color: #FFF;
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .right-price {
    display: flex;
    align-items: center;
    line-height: normal;
    margin-bottom: 6px;
  }

  .right-priceCount {
    color: var(---sui_color_promo, #FA6338);
    font-size: 20px;
    font-weight: 700;
  }

  .right-priceNormal {
    color: var(---sui_color_gray_dark1, #222);
  }

  .right-pricePrecent {
    border-radius: 2px;
    border: 1px solid #FA6338;
    padding: 0px 2px;
    color: var(---sui_color_discount, #FA6338);
    font-size: 12px;
    margin-left: 8px;
  }

  .right-btn {
    display: flex;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    background: #000;
    height: 32px;

    font-size: 14px;
    color: var(---sui_color_white, #FFF);
    font-weight: 700;
  }
}
</style>
