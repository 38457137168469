<template>
  <div
    class="s-swiper-container s-swiper-container-vertical swiper-no-swiping goods-addbag__atmosphere"
  >
    <swiper-container
      ref="swiper"
      init="false"
      destroy-on-disconnected="false"
      class="s-swiper-wrapper goods-addbag__atmosphere-box"
    >
      <swiper-slide
        v-for="(item, key) in addBtnAtmosphereInfo"
        :key="key"
        :style="{
          color: item && item.fontColor || '#000',
          backgroundColor: item && item.backgroundColor || '',
        }"
        class="s-swiper-slide swiper-slide goods-addbag__atmosphere-item"
      >
        <img
          v-if="item && item.icon"
          :src="item.icon"
          class="goods-addbag__atmosphere-itemImg"
        />
        <span
          class="goods-addbag__atmosphere-itemText"
          v-html="tagHtmlText(item)"
        ></span>
      </swiper-slide>
    </swiper-container>
  </div>
</template>
  
<script>
/**
   * @component 人气氛围信息滚动展示框
   */
import { mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
// import { abtservice } from 'public/src/services/abt'
import { Autoplay } from 'swiper/modules'

// 已上报埋点的标签tagId列表
const BTN_REPORTED_LABELS = new Set()

export default {
  name: 'AtmosphereSwiper',
  data() {
    return {
      atmosphereSwiper: null,
    }
  },
  computed: {
    ...mapGetters([
      'atmosphereInfo',
    ]),
    addBtnAtmosphereInfo() {
      return this.atmosphereInfo?.addBag || []
    },
  },
  watch: {
    addBtnAtmosphereInfo: {
      handler(val) {
        // this.atmosphereSwiper?.destroy?.()
        // this.atmosphereSwiper = null
        if (!val?.length) return
        this.$nextTick(() => {
          this.initSwiper()
          this.atmosphereSwiper?.update()
        })
      },
      immediate: true,
    },
  },
  mounted() {
  },
  unmounted() {
    this.atmosphereSwiper?.destroy?.()
  },
  methods: {
    initSwiper() {
      const self = this
      const swiperEl = this.$refs.swiper
      if (!swiperEl) return

      const swiperParams = {
        autoplay: {
          delay: this.addBtnAtmosphereInfo?.length > 1 ? 2000 : 0,
          disableOnInteraction: false,
        },
        modules: [Autoplay],
        speed: 500,
        loop: true,
        direction: 'vertical',
        observer: true,
        observeParents: true,
        noSwiping: true,
        on: {
          slideChange: function (swiper) {
            const realIndex = swiper?.realIndex
            const item = self?.addBtnAtmosphereInfo?.[realIndex]
            if (!item?.labelId || BTN_REPORTED_LABELS.has(item.labelId)) return
            BTN_REPORTED_LABELS.add(item.labelId)
            daEventCenter.triggerNotice({
              daId: '1-6-1-139',
              extraData: {
                // abtest: abtservice.getUserAbtResultForAnalysis({ posKeys: 'hotnews' }).sa || '-',
                labelId: item.labelId,
                location: 'addtobag',
                label_type: item?.label_type
              },
            })
          }
        }
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.atmosphereSwiper = swiperEl.swiper
    },
    tagHtmlText(item) {
      const { labelLang, labelText, numText } = item || {}
      if (labelText && numText) {
        return labelText?.replace?.('%s', `<span>${numText}</span>`) || ''
      }
      return labelLang || ''
    }
  },
}
</script>
  
<style lang="less">
.goods-addbag {
  &__atmosphere {
    position: relative;
    overflow: hidden;
    margin: 0;
    .s-swiper-wrapper{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      box-sizing: content-box;
    }
  }
  

  &__atmosphere-box {
    width: 100%;
    font-size: 12px;
  }

  &__atmosphere-item {
    display: flex;
    justify-content: center;
    align-items: center;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__atmosphere-itemText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;

    & > span {
      font-weight: bold;
    }
  }

  &__atmosphere-itemImg {
    width: 15px;
    margin-right: 2px;
  }

  
}
.goods-addbag__atmosphere{
   .s-swiper-slide{
    opacity: 0;
    transition: opacity 0.5s linear 0s;
  }
   .swiper-slide-active{
    opacity: 1;
  }
}
</style>

