const mutations = {
  SET_DATA(state, payload) {
    state.context = payload
  },
  assignState(state, payload) {
    Object.assign(state, payload)
  },
  setProductIntroData(state, payload) {
    state.productIntroData = Object.assign(state.productIntroData, payload)
  },
  showTipModal(state, payload = {}) {
    const { text, show = true, type = '', twobtn = false } = payload
  
    state.tipModal = {
      text,
      type,
      show,
      twobtn,
    }
  },
  hideTipModal(state) {
    state.tipModal = {
      text: '',
      show: false,
      type: '',
      twobtn: false,
    }
  },
  showLoginModal(state, payload = {}) {
    const { type = '', originRef = null, likeType, callback = null } = payload
  
    state.loginModal = {
      show: true,
      type,
      originRef,
      likeType,
      callback,
    }
    SHEIN_LOGIN.show({
      cb: (status) => {
        this.dispatch('loginModalSucCb', status)
      },
      from: likeType == 'add' ? 'wishlist' : 'other',
    })
  },
  updateBuyBoxRecInfo(state, payload) {
    state.buyBoxRecInfo = payload
  },
  hideLoginModal(state) {
    state.loginModal = {
      show: false,
      type: '',
      originRef: null,
      likeType: '',
      callback: null,
    }
  },
  setBeforeLoginLikeType(state, payload) {
    const { likeType } = payload
    state.beforeLoginLikeType = likeType
  },
  setExclusivePromotionPrice(state, payload) {
    state.productIntroData.detail = Object.assign(
      {},
      state.productIntroData.detail,
      payload
    )
  },
  setTsp(state, payload) {
    state.tsp = payload
  },
  updateRecommendCountData(state, payload = {}) {
    state.recommendCountData = Object.assign(state.recommendCountData, payload)
  },
  updatePrimeShipping(state, payload = {}) {
    state.primeShipping = payload.primeShipping
    state.primeShippingReady = payload.ready
  },
  updateFreeReturnPolicy(state, payload = {}) {
    state.freeReturnPolicy = payload.freeReturnPolicy
  },
  updateHot(state, payload) {
    const {
      productInfo,
      user,
      shipping,
      comment
    } = payload || {}
    const { isPaidUser } = user || {}
    const { commentInfo, commentInfoLocal } = comment || {}

    state.hotModules.productInfo.skuMapCompose.skuAllInfo = productInfo?.skuMapCompose?.skuAllInfo || {}
    state.hotModules.productInfo.sheinClubContent = productInfo?.sheinClubContent || {}

    state.hotModules.user.isPaidUser = isPaidUser

    state.hotModules.comment.commentInfo = commentInfo
    state.hotModules.comment.commentInfoLocal = commentInfoLocal
    state.hotModules.shipping.goodsFreeShippingInfo = shipping?.goodsFreeShippingInfo || null
    state.realTimeReady = true
    setTimeout(() => {
      this.dispatch('realTimeUpdateCallBack')
    }, 500)
  },
}
export default mutations
