<template>
  <div 
    class="product-intro__galleryWrap"
  >
    <div 
      class="product-intro__gallerySticky"
      :class="{'sticky' : isStickyStyle}"
    >
      <GalleryBigPicZoom
        v-if="gallery"
        :is-zoom="cAbtBigPicType.directbig"
        @show-big="onShowBigPicZoom"
      >
        <div
          ref="product-intro__gallery"
          class="product-intro__gallery"
          :class="{ 'product-intro__gallery_active': setFullScreen }"
        >
          <div 
            class="product-intro__thumbs"
          >
            <div
              ref="product-intro__thumbs-inner"
              v-expose="{
                id: '1-8-6-33',
                data: { pic_type }
              }"
              class="product-intro__thumbs-inner"
            >
              <div
                v-for="(item, index) in gallery.detail_image"
                ref="product-intro__thumbs-item"
                :key="index"
                class="product-intro__thumbs-item"
                :class="{
                  'product-intro__thumbs-item_active': activeIndex === index,
                  'product-intro__thumbs-video': item.isVideo,
                  'product-intro__thumbs-videoFix': item.isVideo && autoPosition,
                  'product-intro__thumbs-lastVideo': item.isVideo && index === gallery.detail_image.length - 1
                }"
                @mouseenter="handleThumbItem(index, (imagesModalSlide = false))"
              >
                <template v-if="item.isVideo">
                  <div
                    v-tap="getVideioAnalysis({index, type: 'tap'})"
                    class="product-intro__play"
                    @mouseenter="videoMouseEnter()"
                  >
                    <i class="svgicon svgicon-play1"></i>
                    <div v-expose="getVideioAnalysis({index, type: 'expose'})">
                      {{ language.SHEIN_KEY_PC_17189 }}
                    </div>
                  </div>
                </template>
                <template v-else-if="item.isPicInterest">
                  <div class="product-intro__interest-img">
                    <img :src="`${picInterestInfo.PUBLIC_CDN}/she_dist/images/product_detail/${picInterestInfo.thumbImgPath}.svg`" />
                  </div>
                </template>
                <template v-else>
                  <!--左侧缩略图-->
                  <CropImageContainer
                    :img-src="transformImg({ img: dprInsert(item.thumbnail, '4') })"
                    :lazy="false"
                    :lazy-img="LAZY_IMG"
                    ignore-img-ext
                    :fixed-ratio="fixedRatio"
                    :is-support-crop-image="isSupportCropImage"
                    :transform-img-props="{
                      useOriginImg: true,
                      imgClassName: {
                        'fsp-element': true,
                      },
                    }"
                    :aria-label="`${goodsName} ${language.SHEIN_KEY_PC_20211} ${
                      index + 1
                    }`"
                  />
                </template>
              </div>
            </div>
          </div>
          <div
            ref="MAIN_REF"
            class="product-intro__main"
            tabindex="0"
            :aria-label="language.SHEIN_KEY_PC_15379"
          >
            <client-only>
              <GalleryHeat :img-load="imgLoad" />
            </client-only>
            <div
              ref="product-intro__main-swiper"
              class="s-swiper-container"
              :class="{ 'swiper-container-position': setFullScreen }"
            >
              <swiper-container 
                ref="newSwiper"
                init="false"
                class="s-swiper-wrapper"
              >
                <swiper-slide
                  v-for="(item, index) in galleryDetailImg"
                  :key="item.imgID"
                  class="s-swiper-slide product-intro__main-item"
                  :class="{ 'cursor-zoom-in': cAbtBigPicType.newbig }"
                  :style="{ paddingBottom: `${getFixedRatioPercent}%` }"
                  :main-pic-img-id="item.imgID"
                >
                  <GalleryBigPicZoomItem
                    :image="item.isVideo ? '' : item.origin_image"
                    :img-type="MAIN_IMG_SUPPORT_CUT ? 'Hi-Q1': undefined"
                    :lazy="index > 0 || isUpdateSkc"
                    :enhance-img="index === 0 ? (item.origin_image_url || item.origin_image) : ''"
                    :img-class-name="index === 0 ? {
                      'fsp-element': true,
                    } : void 0"
                    :lcp-hook="!index"
                    :lazy-img="LAZY_IMG"
                    :goods-name="goodsName"
                    :item-index="index"
                    :fixed-ratio="fixedRatio"
                    :is-support-crop-image="isSupportCropImage"
                    @img-load="imgShowCheck(index)"
                  >
                    <template v-if="!item.isPicInterest">
                      <client-only>
                        <!-- 新角标逻辑 - 接入CCC配置 -->
                        <LocateLabels 
                          v-if="locateLabelsInfo"
                          :lazy="index > 0 || isUpdateSkc"
                          :locate-labels="locateLabelsInfo"
                          :lazy-img="LAZY_IMG"
                        />
                      </client-only>
                    </template>
                    <template v-else>
                      <client-only>
                        <ProductIntroLoading />
                        <img
                          class="product-intro__video-lazy"
                          :src="LAZY_IMG"
                          alt="video"
                        />
                      </client-only>
                    </template>
                  </GalleryBigPicZoomItem>
                </swiper-slide>
              </swiper-container>
              <client-only>
                <div
                  ref="commonSwipervPrev"
                  class="common-swiperv2__prev"
                  da-event-click="1-8-6-1"
                >
                  <Icon
                    name="sui_icon_more_left_18px"
                    size="16px"
                    :is-rotate="GB_cssRight"
                  />
                </div>
                <div
                  ref="commonSwipervNext"
                  class="common-swiperv2__next"
                  da-event-click="1-8-6-1"
                >
                  <Icon
                    name="sui_icon_more_right_18px"
                    size="16px"
                    :is-rotate="GB_cssRight"
                  />
                </div>
              </client-only>
              <!-- outfit -->
              <template v-if="showOutfitTips">
                <client-only>
                  <div
                    v-show="!showOutfitRecommend"
                    class="outfit-wrap"
                  >
                    <OutfitImgTips
                      ref="OUTFIT"
                      wrap=".product-intro__main"
                      :outfit-data="outfitData"
                      :show-image="false"
                      :need-active="false"
                      :boundary="outfitBoundary"
                      :cat-id="catId"
                      :language="language"
                      @outfit-click="outfitClickHandle"
                    />
                  </div>
                </client-only>
              </template>
              <!-- vimeo组件 -->
              <SheinVideoPlayer
                v-if="videoUrl"
                v-show="!noShowVideo"
                ref="VimeoPlayer"
                :video-url="videoUrl"
                :video-source-data="videoSourceData"
                :video-player-type="videoPlayerType"
                :poster="videoPoster"
                :poster-alt="goodsName"
                @full-screen-change="isCilckFullScreen"
                @fail-loaded="failLoaded"
              />
              <client-only>
                <GalleryPicInterest
                  v-if="picInterestInfo?.showPicInterest"
                  :show="showInterestGallery"
                  :language="language"
                />
                <img
                  v-if="picInterestInfo?.showPicInterest"
                  class="product-intro__video-lazy"
                  :src="LAZY_IMG"
                  alt="video"
                />
              </client-only>
            </div>
            <!-- Outfit Recommend -->
            <div ref="OUT_RECOMMEND_WRAP_REF">
              <client-only>
                <OutfitRecommend v-show="showOutfitRecommend" />
              </client-only>
            </div>
            <GalleryRecomendEntry
              v-if="newOutfitAbt.showNewOutfitPhoto"
              ref="NEW_GTL_REF"
            />

            <client-only>
              <!-- 人气氛围信息滚动展示框 -->
              <AtmosphereFlow
                v-if="atomFlowConfig.loading"
                :show="showAtmosphereFlow && atomFlowConfig.pageShow"
                :analysis-info="atomFlowConfig.analysis"
                :config="atomFlowConfig"
                location="main_image"
              />
              <!-- 保留款-主图腰带 -->
              <BestDealBelt
                v-if="bestDealConfig.type == 'retentionBelt'"
                :right-text="bestDealConfig.rightText"
                :content-text="bestDealConfig.text"
              />
              <!-- 保留款腰带 - X 天最低价 -->
              <BestDealBelt
                v-else-if="bestDealConfig.type == 'lowestBelt'"
                :content-text="bestDealConfig.text"
              />
              <BlackFridayPromotionBelt
                v-else-if="showBlackFridayBelt"
                v-show="noShowVideo"
                :language="language"
                :belt-info="beltInfo"
                :suggested-sale-price="suggestedSalePrice"
                :compliance-discount-info="complianceDiscountInfo"
                :de-discount-info="deDiscountInfo"
                :is-paid-user="isPaidUser"
                :current-mall-detail="currentMallDetail"
                :shein-club-promotion-info="sheinClubPromotionInfo"
                :compliance-mode="complianceMode"
                :compliance-mode-de="complianceModeDe"
                :estimated-info="getEstimatedInfo"
                :is-allow-belt-jump="isAllowBeltJump"
                :ccc-url="cccUrl"
              />
              <!-- 跟价款腰带 -->
              <BestDealBelt
                v-else-if="bestDealConfig.type == 'followBelt'"
                :content-text="bestDealConfig.text"
              />
            </client-only>
          </div>
        </div>
      </GalleryBigPicZoom>
      <!-- 查看大图 -->
      <client-only>
        <GalleryImageModal
          v-if="renderBigPicZoom"
          :images-show="imagesModalShow"
          :images-arr="galleryDetailBigImg"
          :active-img-id="activeImgId"
          :active-index="activeIndex"
          :video-url="videoUrl"
          :video-poster="videoPoster"
          :goods-name="goodsName"
          :atom-flow-config="atomFlowConfig"
          @change-index="changeActiveIndex"
          @close="imagesModalShow = false"
        />
        <slot name="underGallery"></slot>
      </client-only>
    </div>
  </div>
</template>

<script>
import 'public/src/icon/play1.svg'
import { intorGalleryMixins } from './mixins'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import GalleryHeat from './GalleryHeat'
import AtmosphereFlow from './AtmosphereFlow'
import ProductIntroLoading from '../ProductIntroLoading'
import { transformImg, debounce } from '@shein/common-function'
import LocateLabels from './LocateLabels'
import { abtservice } from 'public/src/services/abt'
import { expose, tap } from 'public/src/pages/common/analysis/directive.js'
import { register } from 'swiper/element'
import { Navigation } from 'swiper/modules'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()
import SheinVideoPlayer from '../Common/SheinVideoPlayer.vue'


daEventCenter.addSubscriber({ modulecode: '1-8-6' })

function animateScroll(element, targetScrollTop, duration) {
  const startScrollTop = element.scrollTop
  const change = targetScrollTop - startScrollTop
  const startTime = performance.now()

  function animate(time) {
    const elapsed = time - startTime
    const progress = Math.min(elapsed / duration, 1) // 确保 progress 不超过 1
    const newScrollTop = startScrollTop + change * progress
    element.scrollTop = newScrollTop

    if (progress < 1) {
      requestAnimationFrame(animate)
    }
  }

  requestAnimationFrame(animate)
}

export default {
  name: 'GalleryNormal',
  directives: {
    expose, tap
  },
  components: {
    AtmosphereFlow,
    GalleryHeat,
    ProductIntroLoading,
    LocateLabels,
    SheinVideoPlayer
  },

  mixins: [intorGalleryMixins],

  props: {
    goodsName: {
      type: String,
      default: ''
    },
    isStickyStyle: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      activeIndex: 0,
      gallerySwiper: null,
      imagesModalShow: false,
      moreGoodsImgsLen: 0,
      autoPosition: false,
      debounceFunc: debounce({ func: this.shouldAutoPosition, wait: 1000 }),
      // videoUrl: '',
      showVideo: false,
      isLoaded: false,
      imagesModalSlide: false,
      setFullScreen: false, //hack部分不能fullScreen的浏览器
      imgLoad: false,
      imgViewMaxIndex: 1, // 图片浏览最大下标, 以1开始
      imgViewIndexs: [1] // 浏览过的图片index集合
    }
  },

  computed: {
    detail() {
      return this.productIntroData?.detail || {}
    },
    brandName() {
      return this.detail.brandInfo?.name || ''
    },
    seriesName() {
      return this.detail.series?.name || ''
    },
    showOutfitRecommend() {
      const { gallery, activeIndex, showOutfitByAbt } = this
      return showOutfitByAbt && (!gallery.detail_image?.[activeIndex]?.isOutfit)
    },
    showOutfitTips() {
      return this.showOutfitByAbt && this.outfitData?.labels?.length
    },
    noShowVideo() {
      return !this.gallery.detail_image?.[this.activeIndex]?.isVideo
    },
    cccVideoPosition() {
      return +this.productIntroData?.cccVideoPosition ? +this.productIntroData.cccVideoPosition : 1
    },
    outfitBoundary() {
      return {
        top: 0,
        bottom: 0,
        right: 55,
        left: 55
      }
    },
    showAtmosphereFlow() {
      const { showOutfitTips, showOutfitRecommend } = this
      if (showOutfitTips) {
        return showOutfitRecommend
      }
      return true
    },
    isHitApollo() {
      if (typeof window === 'undefined') {
        return this.isSupportCropImage
      }
      const { RESOURCE_SDK } = gbCommonInfo
      if (RESOURCE_SDK && RESOURCE_SDK.isSupportCropImage) {
        return true
      }

      return false
    },
    isSquareSrc() {
      // 未命中 Apollo 依旧使用未被去后缀的图片地址
      if (!this.isHitApollo) {
        return false
      }
      return this.galleryDetailImg[0]?.origin_image.includes('_square')
    },
    getFixedRatioPercent() {
      let result = 133
      const { fixedRatio } = this
      const rationArr = fixedRatio ? fixedRatio?.split('-') : []
      if(rationArr.length){
        result = (rationArr[1] / rationArr[0])?.toFixed(2) * 100 || 133
      }else if(this.isSquareSrc){
        // 以下是对非固定容器宽高比 根据imgSrc地址确定的容器大小
        result = 100
      }
      return result
    },
    // 视频封面图
    videoPoster() {
      if (this.videoPlayerType === 'old') {
        return this.LAZY_IMG
      }
      // 视频加载状态时:以商品默认选中的SKC第一张主图（有spu图时以spu图为底图），没有图片拿默认图片
      const spuImg = this.gallery.spu_image?.find(item => item.origin_image)?.origin_image
      const skcImg = this.gallery.detail_image?.find(item => item.is_skc_image)?.origin_image
      return spuImg || skcImg || this.LAZY_IMG
    },
  },

  watch: {
    gallery() {
      // 初始化图片浏览信息数据
      this.imgViewMaxIndex = 1
      this.imgViewIndexs = [1]
      if (typeof window == 'undefined') return
      this.$nextTick(() => {
        this.shouldAutoPosition()
      })
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.shouldAutoPosition()
    })
    window.addEventListener('resize', this.debounceFunc)
    window.addEventListener('scroll', this.withInExposeArea)
  },

  unmounted() {
    window.removeEventListener('resize', this.debounceFunc)
    window.removeEventListener('scroll', this.withInExposeArea)
  },

  methods: {
    transformImg,
    imgShowCheck(index) {
      if (index !== 0) return
      this.imgLoad = true
    },
    /**
     * 初始化 Swiper
     */
    initSwiper(isXhr) {
      const self = this

      let initIndex = 0
      // 切换skc时需要定位到第一张skc图
      if(isXhr) {
        const _initIndex =  this.gallery.detail_image.findIndex(i => !i.is_spu_image) 
        initIndex = _initIndex > -1 ? _initIndex : 0
      }

      const swiperEl = this.$refs.newSwiper
      if (!swiperEl) return

      const swiperParams = {
        modules: [Navigation],
        navigation: {
          prevEl: this.$refs.commonSwipervPrev,
          nextEl: this.$refs.commonSwipervNext,
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        initialSlide: initIndex,
        updateOnImagesReady: true, // 图片加载完重新初始化
        on: {
          slideChangeTransitionStart: swiper => {
            this.activeIndex = swiper.realIndex
            this.updateImgViewInfo()
            this.withInExposeArea()
            if(this.galleryDetailImg[this.activeIndex].isPicInterest){
              this.showInterestGallery = true
              // 商详主图商品利益图曝光
              let interest_type = this.picInterestInfo.interestList.map(item => item.type).join(',')
              daEventCenter.triggerNotice({
                daId: '1-8-6-223',
                extraData: { 
                  interest_type
                }
              })
            }else{
              this.showInterestGallery = false
            }
            if (!self.videoUrl || !self.isLoaded) return
            this.checkIsVideoActive()
          },
          click: (swiper, event) => {
            const targetClassName = event?.target?.className || ''
            if(typeof targetClassName !== 'string') return
            if (targetClassName.includes('product-brand-series')
              || targetClassName.includes('lcp-gallery__hook')
              || targetClassName.includes('cover-frame')
            ) {
              this.activeIndex = swiper.realIndex
              this.handleImage(this.activeIndex)
            }
          },
        },
        observer: true,
        observeParents: true
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.gallerySwiper = swiperEl.swiper
      swiperEl.swiper.loopCreate()
      this.activeIndex = 0
      this.gallerySwiper?.slideToLoop(0, 0)
    },
    // 设置图片埋点数据
    updateImgViewInfo(){
      const { galleryDetailImg, activeIndex, imgViewMaxIndex, imgViewIndexs } = this
      // isOutfit不参与统计
      if(galleryDetailImg[activeIndex].isOutfit) {
        return
      }
      // 是否是利益点
      const isPicInterest = galleryDetailImg[activeIndex]?.isPicInterest
      // outfit下标
      const outfitIndex = galleryDetailImg.findIndex(v => v.isOutfit)
      // activeIndex是从0开始的， mgViewMaxIndex从1开始，所以浏览的图片下标要+1，利益点在Outfit后要单独处理
      const num = isPicInterest && outfitIndex !== -1 && activeIndex > outfitIndex ? activeIndex : activeIndex + 1
      // 更新最大浏览下标
      if(num > imgViewMaxIndex) {
        this.imgViewMaxIndex = num
      }
      // 如果之前没有浏览过的图片，就放入数组, 不包含利益点, isPicInterest为true是利益点
      if(!imgViewIndexs.includes(num) && !isPicInterest) {
        this.imgViewIndexs = [...imgViewIndexs, num]
      }
      if(typeof window !== 'undefined' && window?.SaPageInfo?.page_param) {
        Object.assign(window.SaPageInfo.page_param, {
          img_view_index: this.imgViewMaxIndex || 1,
          gds_img_view_cnt: this.imgViewIndexs.length || 1
        })
      }
    },
    checkIsVideoActive() {
      const item = this.gallery.detail_image[this.activeIndex]
      this.showVideo = item.isVideo
      item.isVideo && this.$refs.VimeoPlayer?.playVideo?.()
      !item.isVideo && this.$refs.VimeoPlayer?.pauseVideo?.()
    },
    videoMouseEnter() {
      this.isShowContol = true
      daEventCenter.triggerNotice({
        daId: '1-8-6-39',
        extraData: {
          duration: '-',
          position: this.cccVideoPosition, // ccc配置的位置
          show_position: this.activeIndex + 1, // 真实展示的位置
          style: this.autoPosition ? 'fab' : 'thumbnail'
        }
      })
    },
    getVideioAnalysis({ index, type }) {
      let show_position = type === 'expose' && this.autoPosition ? this.floatPosition : index + 1
      const data = {
        // eslint-disable-next-line @shein-aidc/abt/abt
        abtest: abtservice.getUserAbtResultForAnalysis({ posKeys: 'CccDetailVideo' })?.sa || '',
        duration: '-',
        position: this.cccVideoPosition, // ccc配置的位置
        show_position, // 真实展示的位置
        style: this.autoPosition ? 'fab' : 'thumbnail',
        is_window_video: '0'
      }
      return {
        id: type === 'expose' ? '1-8-6-38' : '1-8-6-39',
        once: true,
        data
      }
    },
    /**
     * 判断缩略图是否需要自动定位
     */
    shouldAutoPosition() {
      const thumbs = this.$refs['product-intro__thumbs-inner']
      const items = this.$refs['product-intro__thumbs-item']
      if ( items?.length && items[0].offsetHeight * this.cccVideoPosition + this.cccVideoPosition * 5 > thumbs.offsetHeight) {
        this.autoPosition = true
        this.floatPosition = Math.floor(thumbs.offsetHeight / (items[0].offsetHeight + 5)) + 1
      } else {
        this.autoPosition = false
      }
    },
    setThumbsScroll: debounce({
      func: function() {
        if (!this.autoPosition) return
        const thumbs = this.$refs['product-intro__thumbs-inner']
        const currentItem = this.$refs['product-intro__thumbs-item'][
          this.activeIndex
        ]
        if (
          currentItem.offsetTop < thumbs.scrollTop ||
          thumbs.scrollTop <
            currentItem.offsetTop +
              currentItem.offsetHeight -
              thumbs.offsetHeight
        ) {
          animateScroll(thumbs, currentItem.offsetTop, 300)
        }
      },
      wait: 200
    }),
    /**
     * 绑定缩略图移入事件
     */
    handleThumbItem(index) {
      this.$nextTick(() => {
        this.gallerySwiper?.slideToLoop && this.gallerySwiper.slideToLoop(index, 0)
      })
    },
    /**
     * 查看大图更改activeIndex
     */
    changeActiveIndex({ index, imgID }) {
      if(imgID){
        index = this.galleryDetailImg.findIndex(i => i.imgID === imgID)
      }
      this.imagesModalSlide = true
      this.handleThumbItem(index)
    },
    /**
     * 判断是否存在视口 细节图曝光埋点
     */
    withInExposeArea() {
      const wrapper = this.$refs['product-intro__gallery']
      const slides = this.$refs['product-intro__thumbs-item']
      const detailImageLen =
        this.productIntroData.goods_imgs?.detail_image?.length || 0

      const { top: wrapperBoxTop } = wrapper.getBoundingClientRect()

      const wrapperBoxOffsetTop = wrapper.offsetTop
      const wrapperBoxOffsetHeight = wrapper.offsetHeight
      const goodsImageLen = this.productIntroData.goods_imgs.main_image
        ? detailImageLen + 1
        : detailImageLen
      if (
        wrapperBoxTop > 0 &&
        wrapperBoxOffsetTop + wrapperBoxOffsetHeight * 0.6 < window.scrollY + window.innerHeight
      ) {
        if (
          this.moreGoodsImgsLen > 0 &&
          this.activeIndex + 1 > goodsImageLen &&
          slides[this.activeIndex] &&
          !slides[this.activeIndex].isExpose
        ) {
          slides[this.activeIndex].isExpose = true
        }
      }
      this.setOutfitFixed()
      this.setNewGtlFixed()
    },
    /**
     * 重置埋点状态
     */
    restartExpose() {
      const slides = this.$refs['product-intro__thumbs-item']
      for (const item of slides) {
        item.isExpose = false
      }
    }
  }
}
</script>
