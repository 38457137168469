import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
import GalleryBigPicZoom from './GalleryBigPicZoom'
import GalleryBigPicZoomItem from './GalleryBigPicZoomItem'
import dprInsertMixin from '../../utils/dprInsertMixins'
import { transformImg, cloneDeep } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { Icon } from '@shein-aidc/icon-vue3'
import CropImageContainer from './CropImageContainerForDetailMain.vue'
import { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'

// 搭配相关组件
import OutfitRecommend from '../OutfitRecommend' // 搭配入口
import OutfitImgTips from '../OutfitRecommend/OutfitImgTips' // 搭配锚点
import GalleryRecomendEntry from 'public/src/pages/goods_detail_v2/components/RecommendRelatives/GetTheLookNew/GalleryRecomendEntry/index.vue'
import { expose } from 'public/src/pages/common/analysis/directive.js'
daEventCenter.addSubscriber({ modulecode: '1-8-9' })

const EBigPicType = {
  /**
   * newbig 原商详（点击打开全屏大图）
   */
  newbig: 'newbig',
  /**
   * nobig	不可点击图片（如上面需求2）	
   */
  nobig: 'nobig',

  /**
   * directbig 可以在商详直接放大
   */
  directbig: 'directbig'
}

export const intorGalleryMixins = {
  mixins: [dprInsertMixin],
  directives: { expose },
  data() {
    return {
      renderBigPicZoom: false,
      showInterestGallery: false,
      activeImgId: '',
      showOutfit: false,
      showPicThree: false,
      cccUrl: ''
    }
  },
  components: {
    ClientOnly,
    Icon,
    OutfitRecommend,
    OutfitImgTips,
    GalleryRecomendEntry,
    GalleryImageModal: defineAsyncComponent(() => import(/* webpackChunkName: "GalleryImageModal" */'./GalleryImageModal.vue')),
    GalleryBigPicZoom,
    GalleryBigPicZoomItem,
    CropImageContainer,
    OutfitDialog: defineAsyncComponent(() => import('../OutfitRecommend/OutfitDialog.vue')),
    VimeoPlayer: defineAsyncComponent(() => import('../VimeoPlayer.vue')),
    GalleryPicThree: defineAsyncComponent(() => import('../Gallery/GalleryPicThree.vue')), 
    BlackFridayPromotionBelt: defineAsyncComponent(() => import('../Belt/BlackFridayPromotionBelt.vue')),
    BestDealBelt: defineAsyncComponent(() => import('../Belt/BestDealBelt.vue')),
    GalleryPicInterest: defineAsyncComponent(() => import('./GalleryPicInterest.vue')),
  },
  computed: {
    ...mapState(['currentGoodsId', 'productIntroData', 'language', 'LAZY_IMG', 'beltInfo', 'GB_cssRight', 'isUpdateSkc', 'skuInfo', 'saleAttrList', 'fixedRatio', 'locateLabelsInfo', 'RESOURCE_SDK', 'IS_RW', 'MAIN_IMG_SUPPORT_CUT']),
    ...mapGetters([
      'watermarkConfig',
      'pageComponents',
      'retentionPositionConfig',
      'showBlackFridayBelt',
      'getEstimatedInfo',
      'bestDealConfig',
      'forbidAtmosphereFlow',
      'screenAbtConfig',
      'picInterestInfo',
      'suggestedSalePrice',
      'complianceDiscountInfo',
      'deDiscountInfo',
      'complianceMode',
      'complianceModeDe',
      'isPaidUser',
      'sheinClubPromotionInfo',
      'currentMallDetail',
      'videoSourceData'
    ]),
    ...mapGetters('Recommend', ['showOutfitByAbt', 'newOutfitAbt']),
    cccLink() {
      return new CccLink({
        sceneData: this.sceneData || {},
        propData: this.propData || {}
      })
    },
    gallery() {
      if (this.productIntroData && this.productIntroData.goods_imgs) {
        return this.updateGallery()
      }
      return []
    },
    galleryDetailImg () {
      const { detail_image = [] } = this.gallery || {}
      let raw = [...detail_image]
      const showCarouselsWatermark = this?.watermarkConfig?.showCarouselsWatermark
      if (showCarouselsWatermark) {
        raw?.forEach(item => {
          const origin_image_url = item?.origin_image || ''
          if (item?.is_skc_image && origin_image_url.indexOf('_wk_shein') === -1 && origin_image_url.indexOf('images3') > -1) {
            const suffix = origin_image_url.split('.')?.pop() || ''
            item.origin_image = suffix ? origin_image_url.replace(`.${suffix}`, `_wk_shein.${suffix}`) : origin_image_url
            item.origin_image_url = origin_image_url
          }
        })
      }
      return raw
    },
    galleryDetailBigImg() {
      // 过滤主图利益图
      return this.galleryDetailImg.filter(item => !item.isPicInterest)
    },
    pic_type() {
      const type = ['skc']
      this.gallery.detail_image.forEach(item => {
        if(item.is_spu_image && !type.includes('spu')) type.push('spu')
        if(item.sku_code && !type.includes('sku')) type.push('sku')
      })
      return type.join(',')
    },
    // Black 5
    blackFridayImg() {
      const img = this.detail['blackFridayImg'] || ''
      return img ? this.transformImg({ img }) : ''
    },
    isAllowBeltJump(){
      let typeList = ['itemPicking', 'homepageChannel', 'real', 'activityNew', 'activityOld', 'sheinPicks', 'dailyNew', 'game']
      // 不支持Romwe
      return !this.IS_RW && typeList.includes(this.beltInfo?.hrefInfo?.hrefType) && this.cccUrl && this.cccUrl.indexOf('javascript:;') === -1
    },
    //  品牌 logo 
    brandLogo() {
      const { corner_img_left = '' } = this.detail['brandInfo'] || {}
      return corner_img_left ? this.transformImg({ img: corner_img_left }) : ''
    },
    series () { // TODO 详情系列优先级暂时这样处理
      const { corner_img_left, corner_img_right } = this.detail['series'] || {}
      let src = this.GB_cssRight ?  corner_img_right : corner_img_left
      if (src) return this.detail['series']
      const { series_list = [] } = this.productIntroData?.getSeriesAndBrand || {}
      return series_list?.find(_ => _.series_type == 0) || {}
    },
    //  系列 logo
    seriesLogo() {
      const { corner_img_left, corner_img_right } = this.series || {}
      let src = this.GB_cssRight ?  corner_img_right : corner_img_left
      if (!src && this.productIntroData?.getItemPlusSize === 1) { // 大码兜底
        return this.plusSizeImg
      }
      return src ? this.transformImg({ img: src }) : ''
    },
    // 大码兜底图
    plusSizeImg() {
      const src = this.GB_cssRight ? 'curve-plus-r-52ab3e3dc6.png' : 'curve-plus-b50bac3f89.png'
      return `/she_dist/images/plusSize/${src}`
    },
    catId() {
      return this.productIntroData?.detail?.cat_id
    },
    outfitData() {
      return this.productIntroData?.outfitsImg || {}
    },
    showMorDetailImages() {
      return !this.pageComponents.moreDetailConf.isNewComponent && !this.pageComponents.moreDetailConf.notShow
    },
    videoUrl() {
      return this.productIntroData.goods_imgs.video_url || ''
    },
    isShowRetention() {
      const { show, position } = this.retentionPositionConfig
      return show && ['banner', 'bottom'].includes(position)
    },
    isDouble() {
      // 双图模式（rw、shein-motf）
      return this.IS_RW || this.pageComponents?.productIntroGalleryConf?.isRwGallery
    },
    atomFlowConfig() {
      const { hotnews = {} } = this.screenAbtConfig || {}
      const { toastcolor, whereshow } = hotnews?.param || {}
      const pageShow = whereshow !== 'onlyaddtobag' && hotnews?.param?.hotnews && !this.forbidAtmosphereFlow
      const imgShow = whereshow == 'bigpicture' || whereshow == 'andaddtobag' && !this.forbidAtmosphereFlow
      // 真实展示在大图中，用作曝光埋点
      const imgRealShow = imgShow && this.imagesModalShow && !this.forbidAtmosphereFlow
      const addBtnShow = (whereshow == 'andaddtobag' || whereshow == 'onlyaddtobag') && !this.forbidAtmosphereFlow
      return {
        loading: !!hotnews?.param,
        pageShow,
        imgShow,
        addBtnShow,
        toastcolor,
        imgRealShow,
        analysis: {
          // abtest: abtservice.getUserAbtResultForAnalysis({ posKeys: 'hotnews' }).sa
        }
      }
    },
    // 大图实验类型，默认为点击放大
    cAbtBigPicType() {
      const t = this?.productIntroData?.screenAbtConfig?.bigpicture?.param?.bigpicture || EBigPicType.newbig
      return Object.keys(EBigPicType).reduce((obj, k) => {
        obj[k] = EBigPicType[k] === t
        return obj
      }, {})
    },
    isSupportCropImage() {
      return this.RESOURCE_SDK?.isSupportCropImage
    },
    videoPlayerType() {
      return this?.productIntroData?.screenAbtConfig?.nativevideo?.param?.nativevideo || 'old'
    }
  },
  async mounted() {
    this.getPicThreeStatus()
    // this.$nextTick(() => {
    //   this.blackFridayImg && daEventCenter.triggerNotice({ daId: '1-8-3-8' })
    // })
  },
  watch: {
    showBlackFridayBelt (val) {
      if (val) {
        this.initCccLink()
        // 不再腰带组件内曝光，因为会多图下有多个腰带
        daEventCenter.triggerNotice({
          daId: '1-8-9-1',
          target: {
            type: 'belt',
            infoData: this.beltInfo?.labelId || '',
            belt_tp: this.beltInfo?.component || '',
            druplink: this.isAllowBeltJump ? 1 : 0
          }
        })
      }
    },
    fixedRatio () {
      if (this.gallerySwiper) {
        return
      }
      this.resetGallery()
    },
    gallery(newVal, oldVal) {
      if (this.gallerySwiper && newVal && oldVal && JSON.stringify(newVal) == JSON.stringify(oldVal)) return
      this.resetGallery(true)
    },
    'skuInfo.sku_code'(newVal) {
      if(this.saleAttrList?.sku_list?.length <= 1) return //单个sku时不锚定
      if (newVal) {
        const index = this.gallery.detail_image?.findIndex(i => i.sku_code === newVal)
        index && index > -1 && this.handleThumbItem(index)
      }
    },
  },
  methods: {
    ...mapMutations(['assignState']),
    resetGallery(isXhr) {
      // gallery依赖cccconfig接口的数据，必定会触发一次，swiper初始化在这里进行
      // this.gallerySwiper && this.gallerySwiper.destroy(true, true)
      this.$nextTick(() => {
        typeof GB_SHEIN_lazyLoadInstance !== 'undefined' && GB_SHEIN_lazyLoadInstance.update()
        this.restartExpose()
        this.initSwiper(isXhr)
      })
    },
    getPicThreeStatus() { // 是否显示 3P 优先级最高
      const { picture3plabel } = this.screenAbtConfig || {}
      this.showPicThree = !!(this.productIntroData?.detail?.business_model == 1 && picture3plabel?.param?.picture3plabel == 'show')
    },
    /**
     * 点击左右箭头
     */
    handleArrowBtn(direction) {
      if (direction === 'left') {
        this.gallerySwiper.slidePrev()
      } else if (direction === 'right') {
        this.gallerySwiper.slideNext()
      }
      if (this.setThumbsScroll) {
        this.setThumbsScroll(direction)
      }
    },
    /**
     * 点击查看大图
     */
    handleImage() {
      this.renderBigPicZoom = true
      if(this.isDouble){
        this.activeImgId = this.galleryDetailImg[this.bigActiveIndex]?.imgID || ''
      }else{
        this.activeImgId = this.galleryDetailImg[this.activeIndex]?.imgID || ''
      }

      if (!this.cAbtBigPicType.newbig) return
      this.$nextTick(() => {
        this.imagesModalShow = true
        this.triggerEventDetailPic()
      })
    },
    isCilckFullScreen(bol) {
      this.setFullScreen = bol
    },
    failLoaded(bol) {
      this.isLoaded = !bol
      this.checkIsVideoActive()
    },
    outfitClickHandle(key) { // 点击搭配
      this.assignState({ showOutfit: true, renderOutfitDialog: true })
      const { labels, abtest } = this.outfitData
      daEventCenter.triggerNotice({
        daId: '2-21-5',
        extraData: {
          tab_list: `${key + 1}\`${labels[key].label_id}\`\`${labels[key].label}`,
          rec_mark: labels[key].rec_mark,
          abtest,
          activity_from: 'view_outfit'
        }
      })
    },
    setOutfitFixed() {
      const { bottom, right, left } = this.$refs['MAIN_REF'].getBoundingClientRect()
      const viewHeight = window.innerHeight || document.documentElement.clientHeight
      const outfitElWrap = this.$refs['OUT_RECOMMEND_WRAP_REF']
      const paddingLeft = this.GB_cssRight ? left : right
      const paddingBottom = 0
      if (bottom <= viewHeight) {
        outfitElWrap.style = ''
      } else {
        outfitElWrap.style = `position: fixed; bottom:${paddingBottom}px;left:${paddingLeft}px;width:1px;z-index:1;`
      }
    },
    setNewGtlFixed() {
      if (!this.newOutfitAbt?.showNewOutfitPhoto || !this.$refs?.NEW_GTL_REF?.$el) return
      const { bottom, right, left } = this.$refs['MAIN_REF'].getBoundingClientRect()
      const viewHeight = window.innerHeight || document.documentElement.clientHeight
      const newGtlTipEntryDom = this.$refs?.NEW_GTL_REF?.$el
      const paddingLeft = this.GB_cssRight ? left : right
      const paddingBottom = 0
      if (bottom <= viewHeight) {
        newGtlTipEntryDom.style = ''
      } else {
        newGtlTipEntryDom.style = `position: fixed; bottom:${paddingBottom}px;left:${paddingLeft}px;width:1px;z-index:1;`
      }
    },
    updateGallery() {
      const { showMorDetailImages } = this
      const gallery = { ...this.productIntroData.goods_imgs }
      if (!showMorDetailImages) {
        gallery.detail_image = gallery?.detail_image?.filter?.(_ => !_.isMoreDetail)
      }
      // 新搭配去除旧的outfit搭配图
      const len = gallery.detail_image?.length || 0
      if (this.newOutfitAbt?.showNewOutfit && gallery.detail_image?.[len - 1]?.isOutfit) {
        gallery.detail_image.pop()
      }

      // 主图处理商品权益保障利益图
      const { showPicInterest, picInterestAbt, thumbImgPath } = this.picInterestInfo
      
      if(showPicInterest) {
        let picInterestObj = {
          'thumbnail': thumbImgPath,
          'medium_image': '',
          'origin_image': '',
          'isPicInterest': true
        }
        let detail_image = cloneDeep(gallery.detail_image)
        if(['newlast', 'outfitlast'].includes(picInterestAbt)){
          detail_image.push(picInterestObj)
        }else if(picInterestAbt === 'newfour'){
          detail_image.splice(3, 0, picInterestObj)
        }
        gallery.detail_image = detail_image
      }

      if (!gallery.detail_image?.length) return { detail_image: [] }
      return gallery
    },
    transformImg,
    onShowBigPicZoom() {
      if (!this.cAbtBigPicType.directbig) return
      // 显示过一次大图的商品不上报
      this._hasShowBigPicZoomGoodIdMap = this._hasShowBigPicZoomGoodIdMap || {}
      if (this._hasShowBigPicZoomGoodIdMap[this.currentGoodsId]) return
      this.triggerEventDetailPic()
      this._hasShowBigPicZoomGoodIdMap[this.currentGoodsId] = true
    },
    /**
     * 上报大图事件
     */
    triggerEventDetailPic() {
      const curItem = this.gallery.detail_image[this.activeIndex] || {}
      daEventCenter.triggerNotice({
        daId: '1-8-6-2',
        extraData: {
          goods_id: this.currentGoodsId,
          pic_type: curItem.is_spu_image ? 'spu' : curItem.sku_code ? 'sku' : 'skc'
        }
      })
    },
    initCccLink(){
      this.cccUrl = this.cccLink.getLink({
        item: this.beltInfo?.hrefInfo,
        cateLinks: this.cateLinks || {},
      })
    },
  }
}
