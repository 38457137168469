import { createApp } from 'vue'
import { registerBaseContext } from 'public/src/pages/common/common_inject.js'

let AddOnItemDrawerInstance = undefined

export default {
  async showModal(data) {
    // 如果组件已渲染，则返回即可
    if (AddOnItemDrawerInstance) {
      return AddOnItemDrawerInstance.showModal(data)
    }
    if (typeof window === 'undefined') return

    try {
      const AddOnItemDrawer = await import('./AddOnItemDrawer.vue')
      const AddOnItemDrawerAPP = createApp(AddOnItemDrawer.default)
      registerBaseContext(AddOnItemDrawerAPP)
      const instance = AddOnItemDrawerAPP.mount(document.createElement('div'))
      // 要挂载的元素
      const parent = document.body
      parent.appendChild(instance.$el)
      // 将组件实例赋值
      // eslint-disable-next-line require-atomic-updates
      AddOnItemDrawerInstance = instance
      // 展示
      AddOnItemDrawerInstance.showModal(data)
    } catch (error) {
      console.log('import AddOnItemDrawer vue error', error)
    }
  },
  hideModal() {
    if (typeof window === 'undefined') return
    AddOnItemDrawerInstance?.hideModal?.()
  }
}
