<template>
  <div
    v-show="isShow"
    class="product-intro__galleryWrap"
  >
    <div 
      class="product-intro__gallerySticky"
      :class="{'sticky' : isStickyStyle}"
    >
      <GalleryBigPicZoom
        v-if="gallery"
        :is-zoom="cAbtBigPicType.directbig"
        @show-big="onShowBigPicZoom"
      >
        <div
          ref="product-intro__gallery"
          class="product-intro__gallery"
          :class="{ 'product-intro__gallery_active': setFullScreen, confrw: isSh }"
        >
          <div
            class="product-intro__main"
            :class="{ confrw: isSh }"
            tabindex="0"
            :aria-label="language.SHEIN_KEY_PC_15379"
          >
            <div
              ref="product-intro__main-swiper"
              class="s-swiper-container"
              :class="{ 'swiper-container-position': setFullScreen }"
            >
              <swiper-container
                ref="newSwiper"
                init="false"
                class="s-swiper-wrapper"
              >
                <swiper-slide
                  v-for="(item, index) in galleryDetailImg"
                  :key="index"
                  class="s-swiper-slide product-intro__main-itemRw product-intro__main-item"
                  :class="{ 
                    'product-intro__main-item--hook': index === 1,
                    'cursor-zoom-in': cAbtBigPicType.newbig,
                  }"
                  :style="{ paddingBottom: `${getFixedRatioPercent}%` }"
                >
                  <GalleryBigPicZoomItem
                    :image="item.isVideo ? '' : item.origin_image"
                    :lazy="index > 1 || isUpdateSkc"
                    :img-class-name="index < 2 ? {
                      'fsp-element': true,
                    } : void 0"
                    :lcp-hook="index <= 1"
                    :lazy-img="LAZY_IMG"
                    :goods-name="goodsName"
                    :item-index="index"
                    :fixed-ratio="fixedRatio"
                    :is-support-crop-image="isSupportCropImage"
                    :img-type="MAIN_IMG_SUPPORT_CUT ? 'Hi-Q1': undefined"
                  >
                    <template v-if="item.isVideo">
                      <SheinVideoPlayer
                        ref="VimeoPlayer"
                        :poster="videoPoster"
                        :poster-alt="goodsName"
                        :video-url="videoUrl"
                        :video-source-data="videoSourceData"
                        :video-player-type="videoPlayerType"
                        @full-screen-change="isCilckFullScreen"
                        @fail-loaded="failLoaded"
                      />
                    </template>
                    <template v-if="item.isPicInterest && isSh">
                      <GalleryPicInterest
                        v-if="picInterestInfo?.showPicInterest"
                        :show="true"
                        :language="language"
                        :double-pic="true"
                      />
                      <img
                        v-if="picInterestInfo?.showPicInterest"
                        class="product-intro__video-lazy"
                        :src="LAZY_IMG"
                        alt="video"
                      />  
                    </template>
                    <!-- 新角标逻辑 - 接入CCC配置 -->
                    <LocateLabels 
                      v-if="locateLabelsInfo"
                      :lazy="index > 1 || isUpdateSkc"
                      :locate-labels="locateLabelsInfo"
                      :lazy-img="LAZY_IMG"
                      :goods-name="goodsName"
                      :item-index="index"
                      :fixed-ratio="fixedRatio"
                      :is-support-crop-image="isSupportCropImage"
                    />
                    <template v-if="showOutfitByAbt && (item.isOutfit && showOutfitImg)">
                      <client-only>
                        <div class="outfit-wrap">
                          <OutfitImgTips
                            ref="OUTFIT"
                            wrap=".product-intro__main-item--hook"
                            :cat-id="catId"
                            :language="language"
                            :show-image="false"
                            :need-active="false"
                            :outfit-data="outfitData"
                            @outfit-click="outfitClickHandle"
                          />
                        </div>
                      </client-only>
                    </template>
                  </GalleryBigPicZoomItem>
                  <!-- RW 双图腰带 -->
                  <div 
                    v-if="isShowRetention || showBlackFridayBelt || isShowFollowBelt"
                    style="position: relative;"
                  >
                    <RetentionBelt
                      v-if="isShowRetention"
                      style="position: absolute; bottom: 0px;"
                      :language="language"
                      :text="retentionPositionConfig.text"
                      :position="retentionPositionConfig.position"
                    />
                    <BlackFridayPromotionBelt
                      v-else-if="showBlackFridayBelt"
                      style="position: absolute; bottom: 0px;"
                      rw-style="true"
                      :language="language"
                      :belt-info="beltInfo"
                      :suggested-sale-price="suggestedSalePrice"
                      :compliance-discount-info="complianceDiscountInfo"
                      :de-discount-info="deDiscountInfo"
                      :current-mall-detail="currentMallDetail"
                      :is-paid-user="isPaidUser"
                      :shein-club-promotion-info="sheinClubPromotionInfo"
                      :compliance-mode="complianceMode"
                      :compliance-mode-de="complianceModeDe"
                      :estimated-info="getEstimatedInfo"
                      :is-allow-belt-jump="isAllowBeltJump"
                      :ccc-url="cccUrl"
                    />
                    <FollowBelt 
                      v-else-if="isShowFollowBelt"
                      style="position: absolute; bottom: 0px;"
                      :language="language"
                    />
                  </div>
                </swiper-slide>
              </swiper-container>
              <!-- Outfit Recommend -->
              <client-only>
                <OutfitRecommend v-show="showOutfitRecommend" />
              </client-only>
              <div
                class="common-swiperv2__prev"
                :class="{ 'product-intro__hideBtn': activeIndex == 0 }"
                da-event-click="1-8-6-1"
                @click="handleArrowBtn('left')"
              >
                <Icon
                  name="sui_icon_more_left_18px"
                  size="16px"
                  :is-rotate="GB_cssRight"
                />
              </div>
              <div
                class="common-swiperv2__next"
                :class="{
                  'product-intro__hideBtn': singleImage
                    ? activeIndex + 1 == gallery.detail_image.length
                    : activeIndex + 2 == gallery.detail_image.length,
                }"
                da-event-click="1-8-6-1"
                @click="handleArrowBtn('right')"
              >
                <Icon
                  name="sui_icon_more_right_18px"
                  size="16px"
                  :is-rotate="GB_cssRight"
                />
              </div>
            </div>

            <client-only>
              <!-- 人气氛围信息滚动展示框 -->
              <AtmosphereFlow 
                v-if="atomFlowConfig.loading"
                :show="showAtmosphereFlow && atomFlowConfig.pageShow"
                :analysis-info="atomFlowConfig.analysis"
                :config="atomFlowConfig"
                location="main_image"
              />
              <!-- 保留款 - RW 双主图腰带 -->
              <BestDealBelt
                v-if="bestDealConfig.type == 'retentionBelt'"
                :right-text="bestDealConfig.rightText"
                :content-text="bestDealConfig.text"
              />
              <!-- 保留款腰带 - X 天最低价 -->
              <BestDealBelt
                v-else-if="bestDealConfig.type == 'lowestBelt'"
                :content-text="bestDealConfig.text"
              />
              <!-- <BlackFridayPromotionBelt
                v-else-if="showBlackFridayBelt"
                v-show="noShowVideo"
                :language="language"
                :belt-info="beltInfo"
                :estimated-info="getEstimatedInfo"
              /> -->
              <!-- 跟价款腰带 -->
              <BestDealBelt
                v-else-if="bestDealConfig.type == 'followBelt'"
                :content-text="bestDealConfig.text"
              />
            </client-only>
          </div>
          <div
            ref="product-intro__thumbnail"
            class="product-intro__thumbnail"
          >
            <div
              ref="product-intro__thumbnailWrap"
              class="product-intro__thumbnailWrap"
              :class="[isMiddle ? '' : 'product-intro__thumbnailWrap_left']"
            >
              <div
                v-for="(item, index) in gallery.detail_image"
                ref="product-intro__thumbImg"
                :key="index"
                class="product-intro__thumbImg"
                :class="{
                  'product-intro__thumbImg_active':
                    activeIndex == index ||
                    (activeIndex + 1 == index && !singleImage),
                  'product-intro__thumbs-videoRwFix': item.isVideo && autoPosition,
                }"
                da-event-click="1-8-6-2"
                :data-pic_type="item.is_spu_image ? 'spu' : item.sku_code ? 'sku' : 'skc'"
                @mouseenter="handleThumbItem(index, (imagesModalSlide = false))"
                @touchstart="mHandleThumbItem(index, (imagesModalSlide = false))"
              >
                <template v-if="item.isVideo">
                  <div 
                    v-tap="getVideioAnalysis({index, type: 'tap'})"
                    class="product-intro__play"
                    :style="{ paddingBottom: `${ratioPercent}%` }"
                    @mouseenter="videoMouseEnter(index)"
                  >
                    <i class="svgicon svgicon-play1"></i>
                    <div
                      v-expose="getVideioAnalysis({index, type: 'expose'})"
                    >
                      {{ language.SHEIN_KEY_PC_17189 }}
                    </div>
                  </div>
                </template>

                <template v-else-if="item.isPicInterest && isSh">
                  <div 
                    class="product-intro__interest-img"
                    :style="{ paddingBottom: `${ratioPercent}%` }"
                  >
                    <img :src="`${picInterestInfo.PUBLIC_CDN}/she_dist/images/product_detail/${picInterestInfo.thumbImgPath}.svg`" />
                  </div>
                </template>

                <template v-else>
                  <!-- 双图底部缩略图-->
                  <CropImageContainer
                    :img-src="transformImg({ img: dprInsert(item.thumbnail, '3') })"
                    :lazy="false"
                    :lazy-img="LAZY_IMG"
                    ignore-img-ext
                    :fixed-ratio="fixedRatio"
                    :is-support-crop-image="isSupportCropImage"
                    :transform-img-props="{
                      imgClassName: {
                        'fsp-element': true,
                      },
                    }"
                    :aria-label="`${goodsName} ${language.SHEIN_KEY_PC_20211} ${
                      index + 1
                    }`"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
      </GalleryBigPicZoom>
      <!-- 查看大图 -->
      <client-only>
        <GalleryImageModal
          v-if="renderBigPicZoom"
          :images-show="imagesModalShow"
          :images-arr="galleryDetailBigImg"
          :active-img-id="activeImgId"
          :active-index="bigActiveIndex"
          :video-url="videoUrl"
          :video-poster="videoPoster"
          :goods-name="goodsName"
          :atom-flow-config="atomFlowConfig"
          @change-index="changeActiveIndex"
          @close="imagesModalShow = false"
        />

        <GalleryRecomendEntry
          v-if="newOutfitAbt.showNewOutfitPhoto"
          type="underImage"
        />

        <slot name="underGallery"></slot>
      </client-only>
    </div>
  </div>
</template>

<script>
import { intorGalleryMixins } from './mixins'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isMobile, transformImg, debounce } from '@shein/common-function'
import AtmosphereFlow from './AtmosphereFlow'
import LocateLabels from './LocateLabels'
import { abtservice } from 'public/src/services/abt'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import SheinVideoPlayer from '../Common/SheinVideoPlayer.vue'

daEventCenter.addSubscriber({ modulecode: '1-8-6' })
const { GB_cssRight } = gbCommonInfo
import { register } from 'swiper/element'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()

function animateScroll(element, targetScrollLeft, duration) {
  const startScrollLeft = element.scrollLeft
  const change = targetScrollLeft - startScrollLeft
  const startTime = performance.now()

  function animate(time) {
    const elapsed = time - startTime
    const progress = Math.min(elapsed / duration, 1) // 确保 progress 不超过 1
    const newScrollLeft = startScrollLeft + change * progress
    element.scrollLeft = newScrollLeft

    if (progress < 1) {
      requestAnimationFrame(animate)
    }
  }

  requestAnimationFrame(animate)
}


export default {
  name: 'GalleryRw',
  directives: {
    expose
  },
  components: {
    AtmosphereFlow,
    LocateLabels,
    SheinVideoPlayer,
  },
  mixins: [intorGalleryMixins],
  props: {
    goodsName: {
      type: String,
      default: '',
    },
    isSh: {
      type: Boolean,
      default: false,
    },
    isStickyStyle: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      activeIndex: 0,
      gallerySwiper: null,
      imagesModalShow: false,
      moreGoodsImgsLen: 0,
      autoPosition: false,
      isMiddle: true,
      bigActiveIndex: 0,
      singleImage: false,
      isShow: true,
      // videoUrl: '',
      imagesModalSlide: false,
      coordinate: {
        client: {},
        elePosition: {},
      },
      showOutfitImg: false,
      swiperWrapperCss: '',
      showVideo: false,
      isLoaded: false,
      setFullScreen: false,
      imgViewMaxIndex: 2, // 图片浏览最大下标, 以2开始, 双图会展示两张
      imgViewIndexs: [1, 2] // 浏览过的图片index集合, 第一张图以1开始
    }
  },

  computed: {
    showOutfitRecommend() {
      const { activeIndex, gallery, singleImage, showOutfitByAbt } = this
      if (!showOutfitByAbt) return false // 未命中abt直接不展示
      if (singleImage) {
        return !gallery.detail_image[activeIndex]?.isOutfit
      } else {
        return (
          !gallery.detail_image[activeIndex]?.isOutfit &&
          !gallery.detail_image[activeIndex + 1]?.isOutfit
        )
      }
    },
    scale() {
      return this.per / 100
    },
    processWidth() {
      if (this.slider) {
        return 54 * this.scale > 42 ? 42 + 'px' : 54 * this.scale + 'px'
      } else {
        return 0 + 'px'
      }
    },
    processLeft() {
      if (this.slider) {
        return 54 * this.scale > 42 ? 42 + 'px' : 54 * this.scale + 'px'
      } else {
        return 0 + 'px'
      }
    },
    // Black 5
    blackFridayImg() {
      const img = this.productIntroData?.detail?.['blackFridayImg'] || ''
      return img ? this.transformImg({ img }) : ''
    },
    //  品牌 logo
    brand() {
      const { corner_img_left = '', name = '' } =
        this.productIntroData?.detail?.['brandInfo'] || {}
      return {
        img: corner_img_left ? this.transformImg({ img: corner_img_left }) : '',
        name,
      }
    },
    series() {
      const {
        corner_img_left,
        corner_img_right,
        name,
        image_url = '',
      } = this.productIntroData?.detail?.['series'] || {}
      if (this.isSh) {
        let src = GB_cssRight ? corner_img_right : corner_img_left
        return {
          name,
          img: src ? this.transformImg({ img: src }) : '',
        }
      } else {
        return {
          name,
          img: image_url ? this.transformImg({ img: image_url }) : '',
        }
      }
    },
    noShowVideo() {
      return !this.gallery?.detail_image?.[this.activeIndex]?.isVideo
    },
    cccVideoPosition() {
      return +this.productIntroData?.cccVideoPosition ? +this.productIntroData.cccVideoPosition : 1
    },
    showAtmosphereFlow() {
      const { outfitData, showOutfitRecommend } = this
      if (outfitData?.labels?.length) {
        return showOutfitRecommend
      }
      return true
    },
    ratioPercent() {
      // const { fixedRatio } = this
      // if (fixedRatio) {
      //   try {
      //     const rationArr = fixedRatio?.split('-')
      //     const heightPercent = (rationArr[1] / rationArr[0])?.toFixed(2) *  100
      //     return heightPercent
      //   } catch (error) {}
      // }
      // return 133
      const { fixedRatio } = this
      const rationArr = fixedRatio ? fixedRatio?.split('-') : [3, 4]
      const heightPercent = (rationArr[1] / rationArr[0])?.toFixed(2) * 100
      return heightPercent || 133
    },
    getFixedRatioPercent() {
      return this.singleImage ? this.ratioPercent : this.ratioPercent / 2
    },
    // 视频封面图
    videoPoster() {
      if (this.videoPlayerType === 'old') {
        return this.LAZY_IMG
      }
      // 视频加载状态时:以商品默认选中的SKC第一张主图（有spu图时以spu图为底图），没有图片拿默认图片
      const spuImg = this.gallery.spu_image?.find(item => item.origin_image)?.origin_image
      const skcImg = this.gallery.detail_image?.find(item => item.is_skc_image)?.origin_image
      return spuImg || skcImg || this.LAZY_IMG
    }
  },

  watch: {
    singleImage() {
      // this.gallerySwiper && this.gallerySwiper.destroy(true, true)
      // this.gallerySwiper = null
      this.isShow = false
      this.$nextTick(() => {
        this.isShow = true
        this.initSwiper()
        this.handleThumbItem(
          this.singleImage ? this.activeIndex - 1 : this.activeIndex
        )
      })
    },
    per() {
      this.vimeoPlayer.setVolume(this.scale)
    },
    gallery() {
      // 商品数据变化更新图片预览信息
      this.imgViewMaxIndex = 2
      this.imgViewIndexs = [1, 2]
    }
  },

  updated() {
    this.refreshSwiper()
  },

  unmounted() {
    window.removeEventListener('resize', this.refreshSwiper)
    window.removeEventListener('scroll', this.withInExposeArea)
  },

  mounted() {
    this.$nextTick(() => {
      this.refreshSwiper()
    })

    window.addEventListener('resize', this.refreshSwiper)

    window.addEventListener('scroll', this.withInExposeArea)
  },

  methods: {
    transformImg,
    /**
     * 初始化 Swiper
     */
    initSwiper(isXhr) {
      let self = this
      this.showOutfitImg = false

      let initIndex = 0
      // 切换skc时需要定位到第一张skc图
      if(isXhr) {
        const _initIndex =  this.gallery.detail_image.findIndex(i => !i.is_spu_image) 
        initIndex = _initIndex > -1 ? _initIndex : 0
      }
      
      const swiperEl = this.$refs.newSwiper
      if (!swiperEl) return

      const _isInit = !this.gallerySwiper

      const swiperParams = {
        slidesPerView: self.singleImage ? 1 : 2,
        slidesPerGroup: self.singleImage ? 1 : 2,
        initialSlide: initIndex,
        spaceBetween: 10,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        on: {
          transitionEnd: (swiper) => {
            this.activeIndex = swiper.realIndex
            self.bigActiveIndex = swiper.realIndex
            this.slider = this.$refs.slider
            this.updateImgViewInfo(self.singleImage)
            self.withInExposeArea()
            if(this.isSh && (this.galleryDetailImg[this.activeIndex].isPicInterest || (this.galleryDetailImg[this.activeIndex + 1]?.isPicInterest))){
              // 商详主图商品利益图曝光
              let interest_type = this.picInterestInfo.interestList.map(item => item.type).join(',')
              daEventCenter.triggerNotice({
                daId: '1-8-6-223',
                extraData: { 
                  interest_type
                }
              })
            }
            if (!self.videoUrl || !self.isLoaded) return
            this.checkIsVideoActive()
          },
          click: (swiper, event) => {
            const targetClassName = event?.target?.className || ''
            if(typeof targetClassName !== 'string') return
            if (targetClassName.includes('lcp-gallery__hook')
              || targetClassName.includes('cover-frame')) {
              this.activeIndex = swiper.realIndex
              this.bigActiveIndex = Number(
                event.target.getAttribute('index')
              )
              self.handleImage()
            }
          },
          init: () => {
            this.$nextTick(() => {
              this.showOutfitImg = true
            })
          },
        },
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.gallerySwiper = swiperEl.swiper
      this.activeIndex = 0
      this.gallerySwiper?.slideTo(0, 0)
      if (!_isInit) { // 修复重复初始化initSwiper时 outfti不显示问题
        this.$nextTick(() => {
          this.showOutfitImg = true
        })
      }
    },
    // 设置图片埋点数据
    updateImgViewInfo(isSingleImage) {
      const { activeIndex } = this
      // 如果是单图，只展示一张，只需要处理一个activeIndex，如果是多图，需要展示两张图片，需要处理两个activeIndex
      this.updateSaPageInfoPageParams(activeIndex)
      if(!isSingleImage) {
        this.updateSaPageInfoPageParams(activeIndex + 1)
      }
    },
    // 更新埋点参数
    updateSaPageInfoPageParams(index) {
      const { galleryDetailImg, imgViewMaxIndex, imgViewIndexs } = this
      // isOutfit不参与统计
      if(galleryDetailImg[index].isOutfit) {
        return
      }
      // 是否是利益点
      const isPicInterest = galleryDetailImg[index]?.isPicInterest
      // outfit下标
      const outfitIndex = galleryDetailImg.findIndex(v => v.isOutfit)
      // activeIndex是从0开始的， mgViewMaxIndex从1开始，所以浏览的图片下标要+1，利益点在Outfit后要单独处理
      const num = isPicInterest && outfitIndex !== -1 && index > outfitIndex ? index : index + 1
      // 更新最大浏览下标
      if(num > imgViewMaxIndex) {
        this.imgViewMaxIndex = num
      }
      // 如果之前没有浏览过的图片，就放入数组, 不包含利益点, isPicInterest为true是利益点
      if(!imgViewIndexs.includes(num) && !isPicInterest) {
        this.imgViewIndexs = [...imgViewIndexs, num]
      }
      if(typeof window !== 'undefined' && window?.SaPageInfo?.page_param) {
        Object.assign(window.SaPageInfo.page_param, {
          img_view_index: this.imgViewMaxIndex,
          gds_img_view_cnt: this.imgViewIndexs.length
        })
      }
    },
    checkIsVideoActive () {
      let item = {}
      if(this.singleImage) {
        item = this.gallery.detail_image[this.activeIndex]
      } else {
        item = this.gallery.detail_image[this.activeIndex]?.isVideo ? this.gallery.detail_image[this.activeIndex] : this.gallery.detail_image[this.activeIndex + 1]
      }
      this.showVideo = item.isVideo
      if (this.$refs.VimeoPlayer && this.$refs.VimeoPlayer.length) {
        // 当前视图是video 播放视频
        item.isVideo && this.$refs.VimeoPlayer[0].playVideo()
        // 当前视图 不是video 暂停视频
        !item.isVideo && this.$refs.VimeoPlayer[0].pauseVideo()
      } else {
        item.isVideo && this.$refs.VimeoPlayer?.playVideo?.()
        !item.isVideo && this.$refs.VimeoPlayer?.pauseVideo?.()
      }
    },
    videoMouseEnter(index) {
      daEventCenter.triggerNotice({
        daId: '1-8-6-39',
        extraData: {
          // abtest: abtservice.getUserAbtResultForAnalysis({ posKeys: 'CccDetailVideo' })?.sa || '',
          duration: '-',
          position: this.cccVideoPosition, // ccc配置的位置
          show_position: index + 1, // 真实展示的位置
          style: this.autoPosition ? 'fab' : 'thumbnail'
        }
      })
    },
    getVideioAnalysis({ index, type }) {
      let show_position = type === 'expose' && this.autoPosition ? this.floatPosition : index + 1
      const data = {
        // eslint-disable-next-line @shein-aidc/abt/abt
        abtest: abtservice.getUserAbtResultForAnalysis({ posKeys: 'CccDetailVideo' })?.sa || '',
        duration: '-',
        position: this.cccVideoPosition, // ccc配置的位置
        show_position, // 真实展示的位置
        style: this.autoPosition ? 'fab' : 'thumbnail',
        is_window_video: '0'
      }
      return {
        id: type === 'expose' ? '1-8-6-38' : '1-8-6-39',
        once: true,
        data
      }
    },
    setThumbsScroll: debounce({
      func: function (direction) {
        if (this.isMiddle) return
        const thumbnail = this.$refs['product-intro__thumbnail']
        const currentItem =
          this.$refs['product-intro__thumbImg'][this.activeIndex]

        if (!thumbnail || !currentItem) return

        let distance =
          direction == 'right'
            ? currentItem.getBoundingClientRect().x -
              thumbnail.getBoundingClientRect().width
            : currentItem.getBoundingClientRect().x -
              thumbnail.getBoundingClientRect().x
        if (
          (direction == 'right' && distance >= 0) ||
          (direction == 'left' && distance < 0)
        ) {
          const targetScrollLeft = direction === 'right'
            ? currentItem.offsetLeft
            : thumbnail.scrollLeft - currentItem.getBoundingClientRect().width

          animateScroll(thumbnail, targetScrollLeft, 200)
        }
      },
      wait: 200,
    }),
    mHandleThumbItem(index) {
      if (isMobile()) {
        this.handleThumbItem(index)
      }
    },
    /**
     * 绑定缩略图移入事件
     */
    handleThumbItem(index) {
      this.$nextTick(() => {
        this.gallerySwiper?.slideTo && this.gallerySwiper.slideTo(index, 0)
      })
    },
    /**
     * 查看大图更改activeIndex
     */
    changeActiveIndex({ index, imgID }) {
      if(imgID){
        index = this.galleryDetailImg.findIndex(i => i.imgID === imgID)
      }
      let direction = index - this.activeIndex > 0 ? 'right' : 'left'
      this.imagesModalSlide = true
      this.handleThumbItem(index)
      this.setThumbsScroll(direction)
    },
    /**
     * 判断是否存在视口 细节图曝光埋点
     */
    withInExposeArea() {
      const wrapper = this.$refs['product-intro__gallery']
      const slides = this.$refs['product-intro__thumbImg']
      const detailImageLen =
        this.productIntroData.goods_imgs?.detail_image?.length || 0
      const wrapperBoxTop = wrapper.getBoundingClientRect().top
      const wrapperBoxOffsetTop = wrapper.offsetTop
      const wrapperBoxOffsetHeight = wrapper.offsetHeight
      const goodsImageLen = this.productIntroData.goods_imgs.main_image
        ? detailImageLen + 1
        : detailImageLen

      if (
        wrapperBoxTop > 0 &&
        wrapperBoxOffsetTop + wrapperBoxOffsetHeight * 0.6 < window.scrollY + window.innerHeight
      ) {
        if (
          this.moreGoodsImgsLen > 0 &&
          this.activeIndex + 1 > goodsImageLen &&
          slides?.[this.activeIndex] &&
          !slides?.[this.activeIndex].isExpose
        ) {
          daEventCenter.triggerNotice({
            daId: '1-8-6-33',
            extraData: {
              pic_type: this.pic_type
            }
          })
          slides[this.activeIndex].isExpose = true
        }
        if (
          slides?.[this.activeIndex + 1] &&
          !slides?.[this.activeIndex + 1]?.isExpose
        ) {
          daEventCenter.triggerNotice({
            daId: '1-8-6-33',
            extraData: {
              pic_type: this.pic_type
            }
          })
          slides[this.activeIndex + 1].isExpose = true
        }
      }
    },
    /**
     * 重置埋点状态
     */
    restartExpose() {
      const slides = this.$refs['product-intro__thumbImg']

      for (const item of slides) {
        item.isExpose = false
      }
    },
    /**
     * 轮播 缩略图 展示模式
     */
    refreshSwiper: debounce({
      func: function () {
        //缩略图超多情况判断
        let thumbnailWidth = this.$refs['product-intro__thumbnail'].offsetWidth
        let thumbnailWrapWidth =
          this.$refs['product-intro__thumbnailWrap'].offsetWidth
        this.isMiddle = thumbnailWidth > thumbnailWrapWidth
        const items = this.$refs['product-intro__thumbImg']
        if (
          items?.length &&
          items[0].offsetWidth * this.cccVideoPosition + this.cccVideoPosition * 5 > thumbnailWidth
        ) {
          this.autoPosition = true
          this.floatPosition = Math.floor(thumbnailWidth / (items[0].offsetWidth + 5)) + 1
        } else {
          this.autoPosition = false
        }
        //轮播单双图判断
        this.singleImage = document.body.clientWidth <= 1150
      },
      wait: 200,
    })
  }
}
</script>

<style lang="less" scoped>
.product-intro {
  &__galleryWrap {
    width: 730px;
    &.confrw {
      width: 730px;
    }
  }
  &__gallery {
    padding-left: 0;
    flex-direction: column;
  }
  &__main {
    width: 730px;
    &.confrw {
      width: 730px;
    }
  }
  &__main-itemRw {
    width: 50%;
    // padding-bottom: 65%;
  }
  &__main-item:first-child {
    margin-right: 10px;
  }
  &__thumbnail {
    margin-top: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    height: 99px;
    &::-webkit-scrollbar {
      width: 5px;
      background: #fff;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #fff;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(224, 224, 224, 1);
      }
    }
  }
  &__thumbnailWrap {
    position: absolute;
    transform: translateX(-50%);
    top: 0;
    left: 50%;
    white-space: nowrap;
  }
  &__thumbnailWrap_left {
    transform: translateX(0);
    top: 0;
    left: 0;
  }
  &__thumbImg {
    // height: 90px;
    display: inline-block;
    .margin-r(5px);
    background: #f5f5f5;
    opacity: 0.4;
    width: 68px;
    position: relative;
    overflow: hidden;
    img {
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &_active {
      opacity: 1;
    }
  }
  &__thumbs-videoRwFix {
    position: sticky;
    right: 0;
    opacity: 1;
  }
  &__hideBtn {
    display: none;
  }
  .product-series-flag {
    /*rw:begin*/
    width: 139px;
    top: auto;
    right: auto;
    bottom: 20px;
    .left(20px);
    background-color: #fff;
  }
  &__play {
    .svgicon {
      font-size: 22px;
      border-radius: 40px;
      top: calc(50% - 30px);
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
    div {
      font-size: 12px;
      bottom: calc(50% - 30px);
      color: #000;
    }
  }
}
@media (max-width: 1440px) {
  .product-intro {
    &__gallery, &__galleryWrap {
      width: 730px;
    }
    &__main {
      width: 730px;
    }
  }
}
@media (max-width: 1366px) {
  .product-intro {
    &__gallery, &__galleryWrap {
      width: 630px;
      &.confrw {
        width: 630px;
      }
    }
    &__main {
      width: 630px;
      &.confrw {
        width: 630px;
      }
    }
  }
}
@media (max-width: 1150px) {
  .product-intro {
    &__gallery, &__galleryWrap {
      width: 524px;
      &.confrw {
        width: 524px;
      }
    }
    &__gallery {
      padding-left: 60px;
    }
    &__main {
      width: 464px;
      &.confrw {
        width: 464px;
      }
    }
  }
}
@media (max-width: 1074px) {
  .product-intro {
    &__galleryWrap {
      width: 48.7%;
      &.confrw {
        width: 48.7%;
      }
    }
    &__gallery,
    &__main {
      width: 100%;
      &.confrw {
        width: 100%;
      }
    }
  }
}
</style>
