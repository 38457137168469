<script>
export default {
  name: 'SizeSelectItemTitle'
}
</script>
<script setup>
import { defineProps, defineAsyncComponent, computed, inject } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { Icon } from '@shein-aidc/icon-vue3'
const { IS_RW = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : { }

const CommonLocalSizeSelect = defineAsyncComponent(() => import('public/src/pages/goods_detail_v2/components/CommonLocalSizeSelect.vue'))

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  index: {
    type: Number,
    default: 0,
  },
  localSizeComputed: {
    type: Object,
    default: () => ({}),
  },
  currentSelectedCountry: {
    type: String,
    default: '',
  },
  notSelectedTipsAnim: {
    type: Boolean,
    default: false,
  },
  notSelectedTips: {
    type: Object,
    default: () => ({}),
  },
  country: {
    type: String,
    default: '',
  },
})
const config = inject('config')
const language = inject('language')
const commonFn = inject('commonFn')
const setSizeRefs = inject('setSizeRefs')
const showNewSizeLocal = computed(() => config.isNewSizeLocal && props.localSizeComputed?.hasMultiLocal && props.item?.attr_id == 87 && !props.index)
const showSizeLocal = computed(() => props.localSizeComputed?.hasLocalSize && props.item?.attr_id == config.sizeSaleAttrId && props.currentSelectedCountry)
const showSizeGuideInTitle = computed(() => config.showSizeGuideInTitle && props.item?.attr_id == config.sizeSaleAttrId)
const showErrorTip = computed(() => props.notSelectedTips?.includes(String(props.item?.attr_id)))
const sizeSelectClick = () => {
  daEventCenter.triggerNotice({
    daId: '1-8-6-128',
    extraData: {
      choose_type: props.currentSelectedCountry ? 1 : 0
    }
  })
}
const onChangeSelectCountry = (key) => {
  commonFn.onChangeSelectCountry(key)
}
</script>

<template>
  <div
    class="product-intro__size-title"
    tabindex="0"
    :aria-label="item.attr_name"
  >
    {{ item.attr_name }}
    <ClientOnly>
      <template v-if="showNewSizeLocal">
        <CommonLocalSizeSelect
          v-expose="{ id: '1-8-6-127', data: { choose_type: !!country ? 1 : 0 } }"
          :value="currentSelectedCountry"
          :multi-local-size="localSizeComputed.multiLocalSize"
          :language="language"
          :style="{ backgroundColor: '#F6F6F6', border: 'none', marginLeft: '5px' }"
          :default-text="language.SHEIN_KEY_PC_24641"
          :is-new-size-local="config.isNewSizeLocal"
          @click-handle="sizeSelectClick"
          @update:value="onChangeSelectCountry"
        />
      </template>
      <template v-else-if="showSizeLocal">
        <span class="country">({{ currentSelectedCountry }})</span>
      </template>
      <div
        v-if="showSizeGuideInTitle"
        class="product-intro__size-guide title"
        da-event-click="1-8-6-6"
        @click="commonFn.openSizeGuideModal(undefined, { fromScene: 'detail_title' })"
      >
        <Icon
          class="size-guide-icon"
          name="sui_icon_sizeguide_24px"
          :size="IS_RW ? '12px' : '24px'" 
          :custom-style="{ verticalAlign: 'none'}"
        />
        <span class="product-intro__size-guide-t">
          {{
            language.SHEIN_KEY_PC_16557
          }}
        </span>
      </div>
      <div
        v-show="showErrorTip"
        :ref="setSizeRefs('errorTipRef', item.attr_id)"
        class="product-intro__size-error-tips"
        tabindex="-1"
        :aria-describedby="language.SHEIN_KEY_PC_14593"
        :class="{ 'animated animate-shake': notSelectedTipsAnim }"
      >
        {{ language.SHEIN_KEY_PC_20101 }} {{ item.attr_name }}
      </div>
    </ClientOnly>
  </div>
</template>

<style lang="less">
.product-intro {
  &__size-guide,
  &__size-check {
    display: inline-block;
    color: @sui_color_link;
    font-size: 12px;
    cursor: pointer;
    line-height: 1;
  }
  &__size-guide {
    .margin-r(15px);
    color: @sui_color_link;
    &:hover {
      .product-intro__size-guide-t {
        text-decoration: underline;
      }
    }
    .size-guide-icon {
      vertical-align: -5px;
      .margin-r(5px);
      color: @sui_color_link;
      /* rw:begin*/
      .margin-r(0);
      vertical-align: -1px;
    }
    &.title {
      font-weight: normal;
      margin-left: 20px;
    }
  }
  &__size-error-tips {
    .margin-l(10px);
    display: inline-block;
    color: #d53333;
    font-size: 13px;
    font-weight: normal;
  }
}
</style>
