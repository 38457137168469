<template>
  <template
    v-for="item in labelItems"
    :key="item.key"
  >
    <img
      v-if="item.isImgType && item.icon"
      :src="lazy ? lazyImg : item.icon"
      :data-src="item.icon"
      :class="item.cls"
      tabindex="0"
      role="img"
    />
    <div
      v-else
      :class="item.cls"
      :style="getTextStyle(item)"
    >
      {{ item.labelText }}
    </div>
  </template>
</template>
<script name="LocateLabels" setup>
/**
 * @file 主图信息角标
 */

import { computed } from 'vue'

const labelConfig = {
  UPPER_LEFT_LABEL: {
    cls: 'product-item-upperleft',
  },
  UPPER_RIGHT_LABEL: {
    cls: 'product-item-upperright',
  },
  LOWER_LEFT_LABEL: {
    cls: 'product-item-lowerleft',
  },
  LOWER_RIGHT_LABEL: {
    cls: 'product-item-lowerright',
  },
}

const GB_cssRight = !!gbCommonInfo?.GB_cssRight
const seriesLabels = ['series_image', 'series_jointly_image']
const brandLabels = ['brand_image', 'brand_jointly_image']

const getLabelItems = (labels, isLazy) => {
  const data = labels || {}
  const result = []
  Object.keys(data).forEach(key => {
    if (!Object.values(data[key] || {}).length) {
      return
    }
    const materialValueKey = data[key].materialValueKey
    const isImgType = data[key].subscriptType === 'image' || [...seriesLabels, ...brandLabels].includes(materialValueKey)
    let icon = data[key].icon || ''

    if (seriesLabels.includes(materialValueKey)) {
      icon = GB_cssRight ? data[key].corner_img_right : data[key].corner_img_left
    } else if (brandLabels.includes(materialValueKey)) {
      icon = data[key].corner_img_left
    }
    icon = icon ? icon.replace(/^http?\:/, '') : ''
    result.push({
      key,
      isImgType,
      cls: {
        [labelConfig[key].cls]: true,
        ['product-locatelabels-img']: isImgType,
        lazyload: isImgType && isLazy,
        ['product-locatelabels-text']: !isImgType,
      },
      icon,
      labelText: data[key].labelLang || data[key].labelLangEn,
      backgroundColor: data[key].backgroundColor,
      fontColor: data[key].fontColor,
    })
  })
  return result
}

const props = defineProps({
  locateLabels: {
    type: Object,
    default: () => ({}),
  },
  lazyImg: {
    type: String,
    default: ''
  },
  lazy: {
    type: Boolean,
    default: true
  },
})

const labelItems = computed(() => {
  return getLabelItems(props.locateLabels, props.lazy)
})

const getTextStyle = (item) => {
  return {
    backgroundColor: item.backgroundColor,
    color: item.fontColor,
  }
}
</script>

<style lang="less" scoped>
.product-item-upperleft {
    position: absolute;
    top: 12px;
    left: 0 /* rtl: ignore */;
}

.product-item-upperright {
    position: absolute;
    top: 12px;
    right: 0 /* rtl: ignore */;
}

.product-item-lowerleft {
    position: absolute;
    bottom: 12px;
    left: 0 /* rtl: ignore */;
}

.product-item-lowerright {
    position: absolute;
    bottom: 12px;
    right: 0 /* rtl: ignore */;
}

.product-locatelabels-text {
    display: inline-block;
    line-height: 1.35;
    font-size: 14px;
    text-align: center;
    min-width: 50px;
    padding: 0 10px;
    background-color: #000;
    color: #fff;
}

.product-locatelabels-img {
    width: 100%;
    height: auto;

    &.product-item-lowerright {
      bottom: 0px;
    }
    &.product-item-lowerleft {
      bottom: 0px;
    }
    &.product-item-upperright {
      top: 0px;
    }
    &.product-item-upperleft {
      top: 0px;
    }
}
</style>
